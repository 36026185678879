<template>
  <div class="quota-multiple">
    <div v-if="!isMobile" class="quota-day__time">
      <div class="desktopWrapper mod-sample">
        <span class="quota-day__time-text">с</span>

        <span class="quota-day__time-val">
          {{ getTimeFrom }}
        </span>

        <span class="quota-day__time-text">по</span>

        <span class="quota-day__time-val">
          {{ getTimeTo }}
        </span>
      </div>
    </div>

    <div class="quota-multiple__content">
      <div
        v-for="(item, index) in quota"
        :key="item.id"
        class="quota-multiple__item"
      >
        <SampleContent :quota="item" />
        <div v-if="isMobile" class="quota-multiple__item-buttons">
          <div class="mobileWrapper">
            <span class="quota-day__time-val">
              {{ getTimeFrom }}
            </span>

            <span class="quota-day__title-slash">-</span>

            <span class="quota-day__time-val">
              {{ getTimeTo }}
            </span>
          </div>

          <div class="quota-day__icons">
            <IconPenMobile
              v-if="!isSupport && !isViewer"
              @onClick="handleEditQuota(item)"
            />
            <IconTrashMobile
              v-if="!isExporter && !isSupport && !isViewer"
              @onClick="handleDeleteQuota(item)"
            />
            <div class="details-show-icon">
              <Icon
                name="ArrowDownIcon"
                width="22px"
                height="22px"
                :class="['arrow', { rotated: isOpen[index] }]"
                @onClick="isOpen.splice(index, 1, !isOpen[index])"
              />
            </div>
          </div>
        </div>
        <el-collapse-transition>
          <SampleDetail
            v-show="isOpen[index] && isMobile"
            :quota="item"
            class="mobile-detail"
          />
        </el-collapse-transition>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_IS_MOBILE } from '@/store/actions'
import {
  SUPERVISOR_DELETE_SAMPLE,
  SUPERVISOR_EDIT_SAMPLE,
} from '@/constants/dialogs'
import { mapGetters } from 'vuex'
import Icon from '@/UI/icon/Icon.vue'
import IconPenMobile from '@/UI/icon/IconTrashMobile'
import IconTrashMobile from '@/UI/icon/IconPenMobile'
import SampleContent from './SampleContent'
import SampleDetail from './SampleDetail'

export default {
  name: 'SampleMultiple',
  components: {
    SampleDetail,
    Icon,
    SampleContent,
    IconTrashMobile,
    IconPenMobile,
  },
  props: {
    quota: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: [],
    }
  },
  computed: {
    ...mapGetters({
      isMobile: GET_IS_MOBILE,
    }),
    getTimeFrom() {
      return this.quota[0].time_from
    },
    getTimeTo() {
      return this.quota[0].time_to
    },
  },
  mounted() {
    this.isOpen = Array(this.quota.length).fill(false)
  },
  methods: {
    handleEditQuota(quota) {
      this.setDialog({
        name: SUPERVISOR_EDIT_SAMPLE,
        data: { ...quota, isEdit: true },
        visible: true,
      })
      this.$emit('emitClose')
    },
    handleDeleteQuota(quota) {
      this.setDialog({
        name: SUPERVISOR_DELETE_SAMPLE,
        data: { id: quota.id },
        visible: true,
      })
    },
  },
}
</script>

<style lang="sass">
.quota-multiple
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 20px
  &:last-child
    margin-bottom: 0

  &__content
    flex: 1 0 71%

  &__item
    margin-bottom: 10px

  .desktopWrapper
    display: flex
    &.mod-sample
      gap: 10px

@media (max-width: 1200px)
  .quota-multiple
    flex-direction: column-reverse
    align-items: flex-start
    &__content
      flex: auto
      width: 100%
    &__item
      margin-bottom: 24px
    &__item:last-child
      margin-bottom: 0
    &__item-buttons
      display: flex
      justify-content: space-between
      margin-top: 12px
      .mobileWrapper
        display: flex
        align-items: center
        justify-content: center
        .quota-day__time-val
          background: #fff
          font-weight: 600
          border: none
          font-size: 16px
          margin: 0
          padding: 0
          &--long
            justify-content: flex-start
        .quota-day__title-slash
          margin: 0 4px
        .quota-day__time
          flex: auto
          align-items: center
          justify-content: space-between
          width: 100%
          margin: 22px 0 0
      .quota-day__icons
        display: flex
        align-items: center
        justify-content: space-between
        column-gap: 12px
        .details-show-icon
          display: flex
          justify-content: center
          align-items: center
          width: 32px
          height: 32px
          border-radius: 8px
          background: #f4f4f5
          border: 1px solid #E4E7ED
          .arrow
            transition: transform 0.3s ease
            &.rotated
              transform: rotate(180deg)
            &.iq-icon *
              fill: none
              stroke: $fontColor
    .mobile-detail
      width: 100%
      padding-bottom: 4px
</style>
